import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { Link } from 'react-router-dom'
import { useAllContext } from '../context/AllContext'

import ReactPaginate from 'react-paginate';

const Users = () => {

    const adminInfo = JSON.parse(localStorage.getItem('adminInfo'))

    const { getUsers, users } = useAllContext()

    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);

    useEffect(() => {
        getUsers(adminInfo.token)
    }, [])

    const itemsPerPage = 8
    useEffect(() => {
        if (users) {
            const endOffset = itemOffset + itemsPerPage;
            // Calculate the total number of pages
            setCurrentItems(users.slice(itemOffset, endOffset));
            setPageCount(Math.ceil(users.length / itemsPerPage));
        }
        
    }, [itemOffset, itemsPerPage, users]);
    
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % users.length;
        setItemOffset(newOffset);
        setCurrentPage(event.selected)
    };
    


    return (
        <>
            <div className='main-content'>
                <Header />

                <div className='padding'>
                    <div className='container-fluid'>
                        <div className='row gx-3'>
                            <div className='col-lg-12 col-12'>
                                <div className='card card1'>
                                    <div className="card-header">
                                        <h5 className="mb-0 fs-18 fw-500 text-white">Users</h5>
                                    </div>
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className='table table1 mb-0' style={{ "minWidth": "650px" }}>
                                                <thead>
                                                    <tr>
                                                        <th scope="col" style={{ "width": "50px" }}>#</th>
                                                        <th scope="col">User Name</th>
                                                        <th scope="col">Email</th>
                                                        <th scope="col">Date</th>
                                                        <th scope="col" className='text-center'>Transaction</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        currentItems && currentItems.length > 0 ? currentItems.map((items, ind) => {
                                                            const date = new Date(items.createdAt).toLocaleDateString()
                                                            return <tr key={ind}>
                                                                <td>{currentPage * itemsPerPage + ind + 1 }</td>
                                                                <td>{items.firstName} {items.lastName}</td>
                                                                <td>{items.email}</td>
                                                                <td>{date}</td>
                                                                <td className='text-center d-flex justify-content-center'>
                                                                    <Link to={`/user-transaction/${items.id}`} className='btn btn1 py-1 mx-1' style={{"minWidth": "auto"}}>View</Link>
                                                                    <Link to={`/user-deposit/${items.id}`} className='btn btn1 py-1 mx-1' style={{"minWidth": "auto"}}>Deposit</Link>
                                                                    <Link to={`/user-withdraw/${items.id}`} className='btn btn1 py-1 mx-1' style={{"minWidth": "auto"}}>Withdraw</Link>
                                                                </td>
                                                            </tr>
                                                        })
                                                        : 
                                                        <tr>
                                                            <td colSpan={5}><p className='text-center mt-4'>No Records</p></td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                    
                            </div>

                            <div className='col-lg-12 col-12'>

                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel=">"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={1}
                                    pageCount={pageCount}
                                    previousLabel="<"
                                    renderOnZeroPageCount={null}
                                    containerClassName={'pagination'}
                                    pageClassName={'page-item'}
                                    pageLinkClassName={'page-link'}
                                    previousClassName={'page-item'}
                                    previousLinkClassName={'page-link'}
                                    nextClassName={'page-item'}
                                    nextLinkClassName={'page-link'}
                                    breakClassName={'page-item'}
                                    breakLinkClassName={'page-link'}
                                    activeClassName={'active'}
                                    className={'pagination pagination1 mx-auto justify-content-center mt-4 mb-0'}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        </>
    )
}

export default Users
