import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { FaBtc } from 'react-icons/fa'
import { useAllContext } from '../context/AllContext'
import { PiCurrencyEthBold } from 'react-icons/pi'
import { SiTether } from 'react-icons/si'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import axios from 'axios'
import { toast } from 'react-toastify'

const Profit = () => {

    const adminInfo = JSON.parse(localStorage.getItem('adminInfo'))
    
    const [dateValue, setDateValue] = useState([null, null]);

    // Function to format the date to 'yyyy-mm-dd'
    const formatDate = (date) => {
        return date ? date.toISOString().split('T')[0] : null;
    };

    const onChangeDate = (range) => {
        setDateValue(range);
    };

    
    const [profitCurrency, setProfitCurrency] = useState([]);
    const submitProfitCurrency = async () => {

        const formattedStartDate = formatDate(dateValue[0]);
        const formattedEndDate = formatDate(dateValue[1]);
        
        const apiData = {
            date1: formattedStartDate,
            date2: formattedEndDate
        }
        
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/profit-currency`, apiData, {
                headers: {
                    Authorization: `${adminInfo.token}`
                }
            })
            const data = await response.data

            if(data.status === 0){
                // toast.error(data.message)
            }

            if(data.status === 1){
                // toast.success(data.message)
                setProfitCurrency(data)
            }

        } catch (error) {
            console.log('Error:', error.response.data.message);
            toast.error(error.response.data.message)
        }
    }

    useEffect(() => {
        submitProfitCurrency()
    }, [dateValue])
    
    

    return (
        <>
            <div className='main-content'>
                <Header />

                <div className='padding'>
                    <div className='container-fluid'>
                        <div className='row gx-3'>
                            <div className='col-12 mb-3'>
                                <div className='form form1'>
                                    <label className='form-label text-white mb-0 me-2'>Select Date</label>
                                    <DateRangePicker
                                        onChange={onChangeDate} 
                                        value={dateValue} 
                                        className="form-control w-auto" />
                                </div>
                            </div>
                            <div className='col-xl-4 col-md-4 col-sm-6 col-12 mb-4'>
                                <div className='card card1'>
                                    <div className="card-body">
                                        <div className="">
                                            <SiTether className='mb-4 fs-1 text-main' />
                                        </div>
                                        <h3 className="mb-1 fw-semibold">
                                            {profitCurrency.profitUSDT ? profitCurrency.profitUSDT.toFixed(2).replace(/\.?0+$/, '') : '0.00'}
                                        </h3>
                                        <p className="mb-0 text-white-50">USDT</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-xl-4 col-md-4 col-sm-6 col-12 mb-4'>
                                <div className='card card1'>
                                    <div className="card-body">
                                        <div className="">
                                            <FaBtc className='mb-4 fs-1 text-main' />
                                        </div>
                                        <h3 className="mb-1 fw-semibold">
                                            {profitCurrency.profitBTC ? profitCurrency.profitBTC : '0.00'}
                                        </h3>
                                        <p className="mb-0 text-white-50">BTC</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-xl-4 col-md-4 col-sm-6 col-12 mb-4'>
                                <div className='card card1'>
                                    <div className="card-body">
                                        <div className="">
                                            <PiCurrencyEthBold className='mb-4 fs-1 text-main' />
                                        </div>
                                        <h3 className="mb-1 fw-semibold">
                                            {profitCurrency.profitTHB ? profitCurrency.profitTHB.toFixed(2).replace(/\.?0+$/, '') : '0.00'}
                                        </h3>
                                        <p className="mb-0 text-white-50">THB</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        {/* <div className='row gx-3'>
                            <div className='col-lg-6 col-12 mb-4'>
                                <div className='card card1'>
                                    <div className="card-header">
                                        <h5 className="mb-0 fs-18 fw-semibold">Exchange Rate</h5>
                                    </div>
                                    <div className="card-body">
                                        <p className="mb-0 text-center text-white-50">No Recoreds</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-12 mb-4'>
                                <div className='card card1'>
                                    <div className="card-header">
                                        <h5 className="mb-0 fs-18 fw-semibold">Quick Transfer</h5>
                                    </div>
                                    <div className="card-body">
                                        <p className="mb-0 text-center text-white-50">No Recoreds</p>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>

                <Footer />
            </div>
        </>
    )
}

export default Profit
