import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { useAllContext } from '../context/AllContext'
import { useParams } from 'react-router-dom'
import { FaTrash } from 'react-icons/fa'
import axios from 'axios'
import { toast } from 'react-toastify'

const UserTransaction = () => {

    const adminInfo = JSON.parse(localStorage.getItem('adminInfo'))

    const { getUserTransaction, userTransaction, 
        getBankAccounts, bankAccounts, 
        getUserBankAccounts, userBankAccounts, 
        getCryptoAccounts, cryptoAccounts, 
        getUserCryptoAccounts, userCryptoAccounts, 
        getUserDetails, userDetails, 
        getPromptpay, promptpay,
        getUserPromptpay, userPromptpay,
        getUserFees, userFees} = useAllContext()

    const params = useParams()

    const [filteredBankAccounts, setFilteredBankAccounts] = useState({});
    const [filteredCryptoAccounts, setFilteredCryptoAccounts] = useState({});
    const [filteredPromptpay, setFilteredPromptpay] = useState({});

    const [errors, setErrors] = useState({});
    const [errors2, setErrors2] = useState({});
    const [pinErrors, setPinErrors] = useState({});
    const [errorsFees, setErrorsFees] = useState({});
    const [inputValue, setInputValue] = useState({
        bankAccount: '',
        fees: ''
    })
    const [cryptoValue, setCryptoValue] = useState({
        cryptoAccount: '',
        cryptoFees: ''
    })
    const [feesValue, setFeesValue] = useState({
        cryptoWithdrawFees: '',
        bankTransferWithdrawFees: ''
    })
    const [pinValue, setPinValue] = useState({
        pin1: '',
        pin2: '',
        pin3: ''
    })
    
    const [referralCodeValue, setReferralCodeValue] = useState({
        referralCode: ''
    })

    const [promptpayValue, setPromptpayValue] = useState({
        promptpayId: '',
        promptpayFees: ''
    })


    const inputHandler = (e) => {
        const { name, value } = e.target;
        setInputValue({
            ...inputValue,
            [name]: value
        })
    }
    const cryptoHandler = (e) => {
        const { name, value } = e.target;
        setCryptoValue({
            ...cryptoValue,
            [name]: value
        })
    }
    const feesHandler = (e) => {
        const { name, value } = e.target;
        setFeesValue({
            ...feesValue,
            [name]: value
        })
    }
    const referralCodeHandler = (e) => {
        const { name, value } = e.target;
        setReferralCodeValue({
            ...referralCodeValue,
            [name]: value
        })
    }
    const pinHandler = (e) => {
        const { name, value } = e.target;
        setPinValue({
            ...pinValue,
            [name]: value
        })
    }
    const promptpayHandler = (e) => {
        const { name, value } = e.target;
        setPromptpayValue({
            ...promptpayValue,
            [name]: value
        })
    }

    useEffect(() => {
        if(userFees) {
            setFeesValue({
                cryptoWithdrawFees: `${userFees.cryptoWithdrawFees}`,
                bankTransferWithdrawFees: `${userFees.bankTransferWithdrawFees}`
            })
        }

    }, [userFees]);

    useEffect(() => {
        if(userDetails) {
            setReferralCodeValue({
                referralCode: userDetails.referralCode
            })
        }

    }, [userDetails]);
    

    const validate = () => {
        let errors = {};
    
        if (!inputValue.bankAccount) {
            errors.bankAccount = 'Please select bank account';
        }

        if (!inputValue.fees) {
            errors.fees = 'Please select fees';
        }
    
        return errors;
    };


    const formSubmit = async (e) => {
        e.preventDefault();

        const formErrors = validate();
        setErrors(formErrors);


        const bankData = {
            bankAccountId: inputValue.bankAccount,
            fees: inputValue.fees,
            userId: params.userId
        }


        if (Object.keys(formErrors).length === 0) {
            try {
                const resp = await axios.post(`${process.env.REACT_APP_API_URL}/admin/assign-bank-account`, bankData, {
                    headers: {
                        Authorization: `${adminInfo.token}`
                    }
                })
                const data = await resp.data

                if (data.status == 0) {
                    toast.error(data.message)
                }

                if (data.status == 3) {
                    toast.error(data.message)
                }

                if (data.status == 1) {
                    toast.success(data.message)
                    getUserBankAccounts(adminInfo.token, params.userId)
                    setInputValue({
                        bankAccount: '',
                        fees: ''
                    })
                }


            } catch (error) {
                console.log('Error:', error);
            }
        }

    }

    const cryptoValidate = () => {
        let errors = {};
    
        if (!cryptoValue.cryptoAccount) {
            errors.cryptoAccount = 'Please select crypto account';
        }

        if (!cryptoValue.cryptoFees) {
            errors.cryptoFees = 'Please select fees';
        }
    
        return errors;
    };

    const formSubmitCrypto = async (e) => {
        e.preventDefault();

        const formErrors = cryptoValidate();
        setErrors2(formErrors);


        const cryptoData = {
            cryptoAccountId: cryptoValue.cryptoAccount,
            fees: cryptoValue.cryptoFees,
            userId: params.userId
        }


        if (Object.keys(formErrors).length === 0) {
            try {
                const resp = await axios.post(`${process.env.REACT_APP_API_URL}/admin/assign-crypto-account`, cryptoData, {
                    headers: {
                        Authorization: `${adminInfo.token}`
                    }
                })
                const data = await resp.data

                if (data.status == 0) {
                    toast.error(data.message)
                }

                if (data.status == 3) {
                    toast.error(data.message)
                }

                if (data.status == 1) {
                    toast.success(data.message)
                    getUserCryptoAccounts(adminInfo.token, params.userId)
                    setCryptoValue({
                        cryptoAccount: '',
                        cryptoFees: ''
                    })
                }


            } catch (error) {
                console.log('Error:', error);
            }
        }

    }

    const feesValidate = () => {
        let errors = {};
    
        if (!feesValue.cryptoWithdrawFees) {
            errors.cryptoWithdrawFees = 'Please Crypto Withdraw Fees';
        }

        if (!feesValue.bankTransferWithdrawFees) {
            errors.bankTransferWithdrawFees = 'Please select Bank Transfer Withdraw Fees';
        }
    
        return errors;
    };

    const formSubmitFees = async (e) => {
        e.preventDefault();

        const formErrors = feesValidate();
        setErrorsFees(formErrors);


        const details = {
            userId: params.userId,
            cryptoWithdrawFees: feesValue.cryptoWithdrawFees,
            cryptoDepositFees: '0',
            bankTransferWithdrawFees: feesValue.bankTransferWithdrawFees,
            bankTransferDepositFees: '0'
        }


        if (Object.keys(formErrors).length === 0) {
            try {
                const resp = await axios.post(`${process.env.REACT_APP_API_URL}/admin/assign-fees`, details, {
                    headers: {
                        Authorization: `${adminInfo.token}`
                    }
                })
                const data = await resp.data

                if (data.status == 0) {
                    toast.error(data.message)
                }

                if (data.status == 3) {
                    toast.error(data.message)
                }

                if (data.status == 1) {
                    toast.success(data.message)
                    getUserFees(adminInfo.token, params.userId)
                    // setFeesValue({
                    //     cryptoWithdrawFees: '',
                    //     bankTransferWithdrawFees: ''
                    // })
                }


            } catch (error) {
                console.log('Error:', error);
            }
        }

    }

    const deleteUserAccount = async (id) => {
        const bankData = {
            userId: params.userId,
            bankAccountId: `${id}`
        }
        try {
            const resp = await axios.post(`${process.env.REACT_APP_API_URL}/admin/remove-users-bank-account`, bankData, {
                headers: {
                    Authorization: `${adminInfo.token}`
                }
            })
            const data = await resp.data

            if (data.status == 0) {
                toast.error(data.message)
            }

            if (data.status == 1) {
                toast.success(data.message)
                getUserBankAccounts(adminInfo.token, params.userId)
                setFilteredBankAccounts((prevFilteredBankAccounts) =>
                    prevFilteredBankAccounts.filter(
                    (item) => item.userAccounts[0]?.bankAccountId !== id
                    )
                );
            }


        } catch (error) {
            console.log('Error:', error);
        }
        
    }

    const deleteUserCryptoAccount = async (id) => {
        const bankData = {
            userId: params.userId,
            cryptoAccountId: `${id}`
        }
        try {
            const resp = await axios.post(`${process.env.REACT_APP_API_URL}/admin/remove-users-crypto-account`, bankData, {
                headers: {
                    Authorization: `${adminInfo.token}`
                }
            })
            const data = await resp.data

            if (data.status == 0) {
                toast.error(data.message)
            }

            if (data.status == 1) {
                toast.success(data.message)
                getUserCryptoAccounts(adminInfo.token, params.userId)
            }


        } catch (error) {
            console.log('Error:', error);
        }
        
    }

    
    const [errorsReferralCode, setErrorsReferralCode] = useState({});
    const codeValidate = () => {
        let errors = {};
    
        if (!referralCodeValue.referralCode) {
            errors.referralCode = 'Referral Code is required';
        }
    
        return errors;
    }; 

    const formSubmitReferralCode = async (e) => {
        e.preventDefault();

        const formErrors = codeValidate();
        setErrorsReferralCode(formErrors);


        const details = {
            userId: params.userId,
            code: referralCodeValue.referralCode
        }


        if (Object.keys(formErrors).length === 0) {
            try {
                const resp = await axios.post(`${process.env.REACT_APP_API_URL}/admin/add-referral-code`, details, {
                    headers: {
                        Authorization: `${adminInfo.token}`
                    }
                })
                const data = await resp.data

                if (data.status == 0) {
                    toast.error(data.message)
                }

                if (data.status == 1) {
                    toast.success(data.message)
                    getUserDetails(adminInfo.token, params.userId)
                    setReferralCodeValue({
                        referralCode: ''
                    })
                }


            } catch (error) {
                console.log('Error:', error);
            }
        }

    }

    const pinValidate = () => {
        let errors = {};
    
        if (!pinValue.pin1) {
            errors.pin1 = 'Pin is required';
        }

        if (!pinValue.pin2) {
            errors.pin2 = 'Pin is required';
        }

        if (!pinValue.pin3) {
            errors.pin3 = 'Pin is required';
        }
    
        return errors;
    };

    const formSubmitFees2 = async () => {

        const formErrors = feesValidate();
        setErrorsFees(formErrors);


        const details = {
            userId: params.userId,
            cryptoWithdrawFees: feesValue.cryptoWithdrawFees,
            cryptoDepositFees: '0',
            bankTransferWithdrawFees: feesValue.bankTransferWithdrawFees,
            bankTransferDepositFees: '0'
        }


        if (Object.keys(formErrors).length === 0) {
            try {
                const resp = await axios.post(`${process.env.REACT_APP_API_URL}/admin/assign-fees`, details, {
                    headers: {
                        Authorization: `${adminInfo.token}`
                    }
                })
                const data = await resp.data

                if (data.status == 0) {
                    toast.error(data.message)
                }

                if (data.status == 3) {
                    toast.error(data.message)
                }

                if (data.status == 1) {
                    toast.success(data.message)
                    setFeesValue({
                        cryptoWithdrawFees: '',
                        bankTransferWithdrawFees: ''
                    })
                }


            } catch (error) {
                console.log('Error:', error);
            }
        }

    }

    const formSubmitPin = async (e) => {
        e.preventDefault();

        const formErrors = pinValidate();
        setPinErrors(formErrors);


        const details = {
            pin1: pinValue.pin1,
            pin2: pinValue.pin2,
            pin3: pinValue.pin3
        }


        if (Object.keys(formErrors).length === 0) {
            try {
                const resp = await axios.post(`${process.env.REACT_APP_API_URL}/admin/check-pin`, details, {
                    headers: {
                        Authorization: `${adminInfo.token}`
                    }
                })
                const data = await resp.data

                if (data.status == 0) {
                    toast.error(data.message)
                }

                if (data.status == 3) {
                    toast.error(data.message)
                }

                if (data.status == 1) {
                    toast.success(data.message)
                    formSubmitFees2()
                    document.getElementById('pinModal').querySelector('.btn-close').click();
                    setPinValue({
                        pin1: '',
                        pin2: '',
                        pin3: ''
                    })
                }


            } catch (error) {
                console.log('Error:', error);
            }
        }

    }

    const [errorsPromptpay, setErrorsPromptpay] = useState({});
    const promptpayValidate = () => {
        let errors = {};
    
        if (!promptpayValue.promptpayId) {
            errors.promptpayId = 'Please select Promptpay Code';
        }

        if (!promptpayValue.promptpayFees) {
            errors.promptpayFees = 'Please select fees';
        }
    
        return errors;
    };

    const formSubmitPromptpay = async (e) => {
        e.preventDefault();

        const formErrors = promptpayValidate();
        setErrorsPromptpay(formErrors);


        const promptpayData = {
            promptPayId: promptpayValue.promptpayId,
            fees: promptpayValue.promptpayFees,
            userId: params.userId
        }


        if (Object.keys(formErrors).length === 0) {
            try {
                const resp = await axios.post(`${process.env.REACT_APP_API_URL}/admin/assign-promptpay`, promptpayData, {
                    headers: {
                        Authorization: `${adminInfo.token}`
                    }
                })
                const data = await resp.data

                if (data.status == 0) {
                    toast.error(data.message)
                }

                if (data.status == 3) {
                    toast.error(data.message)
                }

                if (data.status == 1) {
                    toast.success(data.message)
                    getUserPromptpay(adminInfo.token, params.userId)
                    setPromptpayValue({
                        promptpayId: '',
                        promptpayFees: ''
                    })
                }


            } catch (error) {
                console.log('Error:', error);
            }
        }

    }

    const deletePromptpay = async (id) => {
        const apiData = {
            userId: params.userId,
            promptPayId: `${id}`
        }

        try {
            const resp = await axios.post(`${process.env.REACT_APP_API_URL}/admin/remove-users-promptpay`, apiData, {
                headers: {
                    Authorization: `${adminInfo.token}`
                }
            })
            const data = await resp.data

            if (data.status == 0) {
                toast.error(data.message)
            }

            if (data.status == 1) {
                toast.success(data.message)
                setFilteredPromptpay((prevFilteredPromptpay) =>
                    prevFilteredPromptpay.filter(
                    (item) => item.userAccounts[0]?.promptPayId !== id
                    )
                );
            }


        } catch (error) {
            console.log('Error:', error);
        }
        
    }  


    useEffect(() => {
        getUserTransaction(adminInfo.token, params.userId)
        getBankAccounts(adminInfo.token)
        getCryptoAccounts(adminInfo.token)
        getUserBankAccounts(adminInfo.token, params.userId)
        getUserCryptoAccounts(adminInfo.token, params.userId)
        getUserDetails(adminInfo.token, params.userId)
        getPromptpay(adminInfo.token)
        getUserPromptpay(adminInfo.token, params.userId)
        getUserFees(adminInfo.token, params.userId);
    }, [])

    useEffect(() => {
        if (bankAccounts && bankAccounts.length > 0 && userBankAccounts && userBankAccounts.length > 0) {
            // Combine the data based on userId
            const combinedData = bankAccounts.map(accounts => {
                return {
                    ...accounts,
                    userAccounts: userBankAccounts.filter(userBanks1 => userBanks1.userId == params.userId).filter(userBanks => userBanks.bankAccountId === accounts.id)
                };
            });

            const filtered = combinedData.filter(items => items.userAccounts.length > 0);
            setFilteredBankAccounts(filtered);
        }
    }, [bankAccounts, userBankAccounts]);

    useEffect(() => {
        if (cryptoAccounts && cryptoAccounts.length > 0 && userCryptoAccounts && userCryptoAccounts.length > 0) {
            // Combine the data based on userId
            const combinedData = cryptoAccounts.map(accounts => {
                return {
                    ...accounts,
                    userAccounts: userCryptoAccounts.filter(userBanks1 => userBanks1.userId == params.userId).filter(userBanks => userBanks.cryptoAccountId === accounts.id)
                };
            });

            const filtered = combinedData.filter(items => items.userAccounts.length > 0);
            setFilteredCryptoAccounts(filtered);
        }
    }, [cryptoAccounts, userCryptoAccounts]);

    useEffect(() => {
        if (promptpay && promptpay.length > 0 && userPromptpay && userPromptpay.length > 0) {
            // Combine the data based on userId
            const combinedData = promptpay.map(items1 => {
                return {
                    ...items1,
                    userAccounts: userPromptpay
                    .filter(items2 => items2.userId == params.userId)
                    .filter(items3 => items3.promptPayId === items1.id)
                };
            });

            const filtered = combinedData.filter(items => items.userAccounts.length > 0);
            setFilteredPromptpay(filtered);
        }
    }, [promptpay, userPromptpay]);
    

    return (
        <>
            <div className='main-content'>
                <Header />

                <div className='padding'>
                    <div className='container-fluid'>
                        <div className='row gx-3'>
                            <div className='col-xl-6 col-12'>
                                <div className='card card1'>
                                    <div className="card-header">
                                        <h5 className="mb-0 fs-18 fw-500 text-white">Assign Bank Account</h5>
                                    </div>
                                    <div className="card-body p-0">
                                        <div className='p-3 p-md-4'>
                                            <div className="row">
                                                <div className='col-12'>
                                                    <form className="form form1" onSubmit={formSubmit}>
                                                        <div className='row gy-3 gx-2'>
                                                            <div className='col-xl-6 col-md-6 col-sm-6 col-12'>
                                                                {/* <label className='form-label text-white'>Bank Accounts</label> */}
                                                                <select className="form-select ms-auto" aria-label="Default select" value={inputValue.bankAccount} name="bankAccount" onChange={inputHandler}>
                                                                    <option selected disabled value="">Select Bank Accounts</option>
                                                                    {
                                                                        bankAccounts && bankAccounts.length > 0 && bankAccounts.map((items, ind) => {
                                                                            return <option key={ind} value={items.id}>{items.bankName} / {items.bankAccountNumber}</option>
                                                                        })
                                                                    }
                                                                </select>
                                                                {errors.bankAccount && <p className="error-text">{errors.bankAccount}</p>}
                                                            </div>
                                                            <div className='col-xl-6 col-md-6 col-sm-6 col-12'>
                                                                {/* <label className='form-label text-white'>Fees</label> */}
                                                                <select className="form-select" aria-label="Default select" value={inputValue.fees} name="fees" onChange={inputHandler}>
                                                                    <option selected disabled value="">Select Fees</option>
                                                                    <option value="0">0%</option>
                                                                    <option value="0.05">0.05%</option>
                                                                    <option value="0.10">0.10%</option>
                                                                    <option value="0.15">0.15%</option>
                                                                    <option value="0.20">0.20%</option>
                                                                    <option value="0.25">0.25%</option>
                                                                    <option value="0.30">0.30%</option>
                                                                    <option value="0.35">0.35%</option>
                                                                    <option value="0.40">0.40%</option>
                                                                    <option value="0.45">0.45%</option>
                                                                    <option value="0.50">0.50%</option>
                                                                    <option value="0.55">0.55%</option>
                                                                    <option value="0.60">0.60%</option>
                                                                    <option value="0.65">0.65%</option>
                                                                    <option value="0.70">0.70%</option>
                                                                    <option value="0.75">0.75%</option>
                                                                    <option value="0.80">0.80%</option>
                                                                    <option value="0.85">0.85%</option>
                                                                    <option value="0.90">0.90%</option>
                                                                    <option value="0.95">0.95%</option>
                                                                    <option value="1">1%</option>
                                                                    <option value="1.05">1.05%</option>
                                                                    <option value="1.10">1.10%</option>
                                                                    <option value="1.15">1.15%</option>
                                                                    <option value="1.20">1.20%</option>
                                                                    <option value="1.25">1.25%</option>
                                                                    <option value="1.30">1.30%</option>
                                                                    <option value="1.35">1.35%</option>
                                                                    <option value="1.40">1.40%</option>
                                                                    <option value="1.45">1.45%</option>
                                                                    <option value="1.50">1.50%</option>
                                                                    <option value="1.55">1.55%</option>
                                                                    <option value="1.60">1.60%</option>
                                                                    <option value="1.65">1.65%</option>
                                                                    <option value="1.70">1.70%</option>
                                                                    <option value="1.75">1.75%</option>
                                                                    <option value="1.80">1.80%</option>
                                                                    <option value="1.85">1.85%</option>
                                                                    <option value="1.90">1.90%</option>
                                                                    <option value="1.95">1.95%</option>
                                                                    <option value="2">2%</option>
                                                                    <option value="2.05">2.05%</option>
                                                                    <option value="2.10">2.10%</option>
                                                                    <option value="2.15">2.15%</option>
                                                                    <option value="2.20">2.20%</option>
                                                                    <option value="2.25">2.25%</option>
                                                                    <option value="2.30">2.30%</option>
                                                                    <option value="2.35">2.35%</option>
                                                                    <option value="2.40">2.40%</option>
                                                                    <option value="2.45">2.45%</option>
                                                                    <option value="2.50">2.50%</option>
                                                                    <option value="2.55">2.55%</option>
                                                                    <option value="2.60">2.60%</option>
                                                                    <option value="2.65">2.65%</option>
                                                                    <option value="2.70">2.70%</option>
                                                                    <option value="2.75">2.75%</option>
                                                                    <option value="2.80">2.80%</option>
                                                                    <option value="2.85">2.85%</option>
                                                                    <option value="2.90">2.90%</option>
                                                                    <option value="2.95">2.95%</option>
                                                                    <option value="3">3%</option>
                                                                    <option value="3.05">3.05%</option>
                                                                    <option value="3.10">3.10%</option>
                                                                    <option value="3.15">3.15%</option>
                                                                    <option value="3.20">3.20%</option>
                                                                    <option value="3.25">3.25%</option>
                                                                    <option value="3.30">3.30%</option>
                                                                    <option value="3.35">3.35%</option>
                                                                    <option value="3.40">3.40%</option>
                                                                    <option value="3.45">3.45%</option>
                                                                    <option value="3.50">3.50%</option>
                                                                    <option value="3.55">3.55%</option>
                                                                    <option value="3.60">3.60%</option>
                                                                    <option value="3.65">3.65%</option>
                                                                    <option value="3.70">3.70%</option>
                                                                    <option value="3.75">3.75%</option>
                                                                    <option value="3.80">3.80%</option>
                                                                    <option value="3.85">3.85%</option>
                                                                    <option value="3.90">3.90%</option>
                                                                    <option value="3.95">3.95%</option>
                                                                    <option value="4">4%</option>
                                                                    <option value="4.05">4.05%</option>
                                                                    <option value="4.10">4.10%</option>
                                                                    <option value="4.15">4.15%</option>
                                                                    <option value="4.20">4.20%</option>
                                                                    <option value="4.25">4.25%</option>
                                                                    <option value="4.30">4.30%</option>
                                                                    <option value="4.35">4.35%</option>
                                                                    <option value="4.40">4.40%</option>
                                                                    <option value="4.45">4.45%</option>
                                                                    <option value="4.50">4.50%</option>
                                                                    <option value="4.55">4.55%</option>
                                                                    <option value="4.60">4.60%</option>
                                                                    <option value="4.65">4.65%</option>
                                                                    <option value="4.70">4.70%</option>
                                                                    <option value="4.75">4.75%</option>
                                                                    <option value="4.80">4.80%</option>
                                                                    <option value="4.85">4.85%</option>
                                                                    <option value="4.90">4.90%</option>
                                                                    <option value="4.95">4.95%</option>
                                                                    <option value="5">5%</option>
                                                                </select>
                                                                {errors.fees && <p className="error-text">{errors.fees}</p>}
                                                            </div>

                                                            <div className='col-12 text-center'>
                                                                <button type='submit' className='btn btn1'>Add</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="table-responsive">
                                            <table className='table table1 mb-0'>
                                                <thead>
                                                    <tr>
                                                        <th scope="col" style={{ "width": "50px" }}>#</th>
                                                        <th scope="col">Bank Name</th>
                                                        <th scope="col">Account Number</th>
                                                        <th scope="col">Fees</th>
                                                        <th scope="col" className='text-center' style={{ "width": "50px" }}>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        filteredBankAccounts && filteredBankAccounts.length > 0 ? filteredBankAccounts.map((items, ind) => {
                                                            return <tr key={ind}>
                                                                <td>{ind + 1}</td>
                                                                <td>{items.bankName}</td>
                                                                <td>{items.bankAccountNumber}</td>
                                                                {
                                                                    items.userAccounts.length > 0 && items.userAccounts.map((itemsFees, ind) => {
                                                                        return <>
                                                                            <td key={`fees${ind}`}>{itemsFees.fees}%</td>
                                                                        </>
                                                                    })
                                                                }
                                                                <td className='text-center'><button onClick={() => deleteUserAccount(items.id)} className="btn btn1 py-1 px-2 bg-danger border-danger text-white rounded-2 fs-14" style={{ "minWidth": "auto" }}><FaTrash /></button></td>
                                                            </tr>
                                                        })
                                                        :
                                                        <tr>
                                                            <td colSpan={5}><p className='text-center mt-4'>No Records</p></td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-xl-6 col-12 mt-4 mt-xl-0'>
                                <div className='card card1'>
                                    <div className="card-header">
                                        <h5 className="mb-0 fs-18 fw-500 text-white">Assign Crypto Account</h5>
                                    </div>
                                    <div className="card-body p-0">
                                        <div className='p-3 p-md-4'>
                                            <div className="row">
                                                <div className='col-12'>
                                                    <form className="form form1" onSubmit={formSubmitCrypto}>
                                                        <div className='row gy-3 gx-2'>
                                                            <div className='col-xl-6 col-md-6 col-sm-6 col-12'>
                                                                <select className="form-select ms-auto" aria-label="Default select" value={cryptoValue.cryptoAccount} name="cryptoAccount" onChange={cryptoHandler}>
                                                                    <option selected disabled value="">Select Crypto Accounts</option>
                                                                    {
                                                                        cryptoAccounts && cryptoAccounts.length > 0 && cryptoAccounts.map((items, ind) => {
                                                                            return <option key={ind} value={items.id}>{items.walletAddress}</option>
                                                                        })
                                                                    }
                                                                </select>
                                                                {errors2.cryptoAccount && <p className="error-text">{errors2.cryptoAccount}</p>}
                                                            </div>
                                                            
                                                            <div className='col-xl-6 col-md-6 col-sm-6 col-12'>
                                                                {/* <label className='form-label text-white'>Fees</label> */}
                                                                <select className="form-select" aria-label="Default select" value={cryptoValue.cryptoFees} name="cryptoFees" onChange={cryptoHandler}>
                                                                    <option selected disabled value="">Select Fees</option>
                                                                    <option value="0">0%</option>
                                                                    <option value="0.05">0.05%</option>
                                                                    <option value="0.10">0.10%</option>
                                                                    <option value="0.15">0.15%</option>
                                                                    <option value="0.20">0.20%</option>
                                                                    <option value="0.25">0.25%</option>
                                                                    <option value="0.30">0.30%</option>
                                                                    <option value="0.35">0.35%</option>
                                                                    <option value="0.40">0.40%</option>
                                                                    <option value="0.45">0.45%</option>
                                                                    <option value="0.50">0.50%</option>
                                                                    <option value="0.55">0.55%</option>
                                                                    <option value="0.60">0.60%</option>
                                                                    <option value="0.65">0.65%</option>
                                                                    <option value="0.70">0.70%</option>
                                                                    <option value="0.75">0.75%</option>
                                                                    <option value="0.80">0.80%</option>
                                                                    <option value="0.85">0.85%</option>
                                                                    <option value="0.90">0.90%</option>
                                                                    <option value="0.95">0.95%</option>
                                                                    <option value="1">1%</option>
                                                                    <option value="1.05">1.05%</option>
                                                                    <option value="1.10">1.10%</option>
                                                                    <option value="1.15">1.15%</option>
                                                                    <option value="1.20">1.20%</option>
                                                                    <option value="1.25">1.25%</option>
                                                                    <option value="1.30">1.30%</option>
                                                                    <option value="1.35">1.35%</option>
                                                                    <option value="1.40">1.40%</option>
                                                                    <option value="1.45">1.45%</option>
                                                                    <option value="1.50">1.50%</option>
                                                                    <option value="1.55">1.55%</option>
                                                                    <option value="1.60">1.60%</option>
                                                                    <option value="1.65">1.65%</option>
                                                                    <option value="1.70">1.70%</option>
                                                                    <option value="1.75">1.75%</option>
                                                                    <option value="1.80">1.80%</option>
                                                                    <option value="1.85">1.85%</option>
                                                                    <option value="1.90">1.90%</option>
                                                                    <option value="1.95">1.95%</option>
                                                                    <option value="2">2%</option>
                                                                    <option value="2.05">2.05%</option>
                                                                    <option value="2.10">2.10%</option>
                                                                    <option value="2.15">2.15%</option>
                                                                    <option value="2.20">2.20%</option>
                                                                    <option value="2.25">2.25%</option>
                                                                    <option value="2.30">2.30%</option>
                                                                    <option value="2.35">2.35%</option>
                                                                    <option value="2.40">2.40%</option>
                                                                    <option value="2.45">2.45%</option>
                                                                    <option value="2.50">2.50%</option>
                                                                    <option value="2.55">2.55%</option>
                                                                    <option value="2.60">2.60%</option>
                                                                    <option value="2.65">2.65%</option>
                                                                    <option value="2.70">2.70%</option>
                                                                    <option value="2.75">2.75%</option>
                                                                    <option value="2.80">2.80%</option>
                                                                    <option value="2.85">2.85%</option>
                                                                    <option value="2.90">2.90%</option>
                                                                    <option value="2.95">2.95%</option>
                                                                    <option value="3">3%</option>
                                                                    <option value="3.05">3.05%</option>
                                                                    <option value="3.10">3.10%</option>
                                                                    <option value="3.15">3.15%</option>
                                                                    <option value="3.20">3.20%</option>
                                                                    <option value="3.25">3.25%</option>
                                                                    <option value="3.30">3.30%</option>
                                                                    <option value="3.35">3.35%</option>
                                                                    <option value="3.40">3.40%</option>
                                                                    <option value="3.45">3.45%</option>
                                                                    <option value="3.50">3.50%</option>
                                                                    <option value="3.55">3.55%</option>
                                                                    <option value="3.60">3.60%</option>
                                                                    <option value="3.65">3.65%</option>
                                                                    <option value="3.70">3.70%</option>
                                                                    <option value="3.75">3.75%</option>
                                                                    <option value="3.80">3.80%</option>
                                                                    <option value="3.85">3.85%</option>
                                                                    <option value="3.90">3.90%</option>
                                                                    <option value="3.95">3.95%</option>
                                                                    <option value="4">4%</option>
                                                                    <option value="4.05">4.05%</option>
                                                                    <option value="4.10">4.10%</option>
                                                                    <option value="4.15">4.15%</option>
                                                                    <option value="4.20">4.20%</option>
                                                                    <option value="4.25">4.25%</option>
                                                                    <option value="4.30">4.30%</option>
                                                                    <option value="4.35">4.35%</option>
                                                                    <option value="4.40">4.40%</option>
                                                                    <option value="4.45">4.45%</option>
                                                                    <option value="4.50">4.50%</option>
                                                                    <option value="4.55">4.55%</option>
                                                                    <option value="4.60">4.60%</option>
                                                                    <option value="4.65">4.65%</option>
                                                                    <option value="4.70">4.70%</option>
                                                                    <option value="4.75">4.75%</option>
                                                                    <option value="4.80">4.80%</option>
                                                                    <option value="4.85">4.85%</option>
                                                                    <option value="4.90">4.90%</option>
                                                                    <option value="4.95">4.95%</option>
                                                                    <option value="5">5%</option>
                                                                </select>
                                                                {errors2.cryptoFees && <p className="error-text">{errors2.cryptoFees}</p>}
                                                            </div>

                                                            <div className='col-12 text-center'>
                                                                <button type='submit' className='btn btn1'>Add</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="table-responsive">
                                            <table className='table table1 mb-0'>
                                                <thead>
                                                    <tr>
                                                        <th scope="col" style={{ "width": "50px" }}>#</th>
                                                        <th scope="col">Wallet Address</th>
                                                        <th scope="col">Fees</th>
                                                        <th scope="col" className='text-center' style={{ "width": "50px" }}>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        filteredCryptoAccounts && filteredCryptoAccounts.length > 0 ? filteredCryptoAccounts.map((items, ind) => {
                                                            return <tr key={ind}>
                                                                <td>{ind + 1}</td>
                                                                <td className='text-wrap'>{items.walletAddress}</td>
                                                                {
                                                                    items.userAccounts.length > 0 && items.userAccounts.map((itemsFees, ind) => {
                                                                        return <>
                                                                            <td key={`fees${ind}`}>{itemsFees.fees}%</td>
                                                                        </>
                                                                    })
                                                                }
                                                                <td className='text-center'><button onClick={() => deleteUserCryptoAccount(items.id)} className="btn btn1 py-1 px-2 bg-danger border-danger text-white rounded-2 fs-14" style={{ "minWidth": "auto" }}><FaTrash /></button></td>
                                                            </tr>
                                                        })
                                                        :
                                                        <tr>
                                                            <td colSpan={5}><p className='text-center mt-4'>No Records</p></td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-xl-6 col-12 mt-4'>
                                <div className='card card1'>
                                    <div className="card-header">
                                        <h5 className="mb-0 fs-18 fw-500 text-white">Assign Promptpay</h5>
                                    </div>
                                    <div className="card-body p-0">
                                        <div className='p-3 p-md-4'>
                                            <div className="row">
                                                <div className='col-12'>
                                                    <form className="form form1" onSubmit={formSubmitPromptpay}>
                                                        <div className='row gy-3 gx-2'>
                                                            <div className='col-xl-6 col-md-6 col-sm-6 col-12'>
                                                                <select className="form-select ms-auto" aria-label="Default select" value={promptpayValue.promptpayId} name="promptpayId" onChange={promptpayHandler}>
                                                                    <option selected disabled value="">Select Promptpay Code</option>
                                                                    {
                                                                        promptpay && promptpay.length > 0 && promptpay.map((items, ind) => {
                                                                            return <option key={ind} value={items.id}>{items.promptPayCode}</option>
                                                                        })
                                                                    }
                                                                </select>
                                                                {errorsPromptpay.promptpayId && <p className="error-text">{errorsPromptpay.promptpayId}</p>}
                                                            </div>
                                                            
                                                            <div className='col-xl-6 col-md-6 col-sm-6 col-12'>
                                                                {/* <label className='form-label text-white'>Fees</label> */}
                                                                <select className="form-select" aria-label="Default select" value={promptpayValue.promptpayFees} name="promptpayFees" onChange={promptpayHandler}>
                                                                    <option selected disabled value="">Select Fees</option>
                                                                    <option value="0">0%</option>
                                                                    <option value="0.05">0.05%</option>
                                                                    <option value="0.10">0.10%</option>
                                                                    <option value="0.15">0.15%</option>
                                                                    <option value="0.20">0.20%</option>
                                                                    <option value="0.25">0.25%</option>
                                                                    <option value="0.30">0.30%</option>
                                                                    <option value="0.35">0.35%</option>
                                                                    <option value="0.40">0.40%</option>
                                                                    <option value="0.45">0.45%</option>
                                                                    <option value="0.50">0.50%</option>
                                                                    <option value="0.55">0.55%</option>
                                                                    <option value="0.60">0.60%</option>
                                                                    <option value="0.65">0.65%</option>
                                                                    <option value="0.70">0.70%</option>
                                                                    <option value="0.75">0.75%</option>
                                                                    <option value="0.80">0.80%</option>
                                                                    <option value="0.85">0.85%</option>
                                                                    <option value="0.90">0.90%</option>
                                                                    <option value="0.95">0.95%</option>
                                                                    <option value="1">1%</option>
                                                                    <option value="1.05">1.05%</option>
                                                                    <option value="1.10">1.10%</option>
                                                                    <option value="1.15">1.15%</option>
                                                                    <option value="1.20">1.20%</option>
                                                                    <option value="1.25">1.25%</option>
                                                                    <option value="1.30">1.30%</option>
                                                                    <option value="1.35">1.35%</option>
                                                                    <option value="1.40">1.40%</option>
                                                                    <option value="1.45">1.45%</option>
                                                                    <option value="1.50">1.50%</option>
                                                                    <option value="1.55">1.55%</option>
                                                                    <option value="1.60">1.60%</option>
                                                                    <option value="1.65">1.65%</option>
                                                                    <option value="1.70">1.70%</option>
                                                                    <option value="1.75">1.75%</option>
                                                                    <option value="1.80">1.80%</option>
                                                                    <option value="1.85">1.85%</option>
                                                                    <option value="1.90">1.90%</option>
                                                                    <option value="1.95">1.95%</option>
                                                                    <option value="2">2%</option>
                                                                    <option value="2.05">2.05%</option>
                                                                    <option value="2.10">2.10%</option>
                                                                    <option value="2.15">2.15%</option>
                                                                    <option value="2.20">2.20%</option>
                                                                    <option value="2.25">2.25%</option>
                                                                    <option value="2.30">2.30%</option>
                                                                    <option value="2.35">2.35%</option>
                                                                    <option value="2.40">2.40%</option>
                                                                    <option value="2.45">2.45%</option>
                                                                    <option value="2.50">2.50%</option>
                                                                    <option value="2.55">2.55%</option>
                                                                    <option value="2.60">2.60%</option>
                                                                    <option value="2.65">2.65%</option>
                                                                    <option value="2.70">2.70%</option>
                                                                    <option value="2.75">2.75%</option>
                                                                    <option value="2.80">2.80%</option>
                                                                    <option value="2.85">2.85%</option>
                                                                    <option value="2.90">2.90%</option>
                                                                    <option value="2.95">2.95%</option>
                                                                    <option value="3">3%</option>
                                                                    <option value="3.05">3.05%</option>
                                                                    <option value="3.10">3.10%</option>
                                                                    <option value="3.15">3.15%</option>
                                                                    <option value="3.20">3.20%</option>
                                                                    <option value="3.25">3.25%</option>
                                                                    <option value="3.30">3.30%</option>
                                                                    <option value="3.35">3.35%</option>
                                                                    <option value="3.40">3.40%</option>
                                                                    <option value="3.45">3.45%</option>
                                                                    <option value="3.50">3.50%</option>
                                                                    <option value="3.55">3.55%</option>
                                                                    <option value="3.60">3.60%</option>
                                                                    <option value="3.65">3.65%</option>
                                                                    <option value="3.70">3.70%</option>
                                                                    <option value="3.75">3.75%</option>
                                                                    <option value="3.80">3.80%</option>
                                                                    <option value="3.85">3.85%</option>
                                                                    <option value="3.90">3.90%</option>
                                                                    <option value="3.95">3.95%</option>
                                                                    <option value="4">4%</option>
                                                                    <option value="4.05">4.05%</option>
                                                                    <option value="4.10">4.10%</option>
                                                                    <option value="4.15">4.15%</option>
                                                                    <option value="4.20">4.20%</option>
                                                                    <option value="4.25">4.25%</option>
                                                                    <option value="4.30">4.30%</option>
                                                                    <option value="4.35">4.35%</option>
                                                                    <option value="4.40">4.40%</option>
                                                                    <option value="4.45">4.45%</option>
                                                                    <option value="4.50">4.50%</option>
                                                                    <option value="4.55">4.55%</option>
                                                                    <option value="4.60">4.60%</option>
                                                                    <option value="4.65">4.65%</option>
                                                                    <option value="4.70">4.70%</option>
                                                                    <option value="4.75">4.75%</option>
                                                                    <option value="4.80">4.80%</option>
                                                                    <option value="4.85">4.85%</option>
                                                                    <option value="4.90">4.90%</option>
                                                                    <option value="4.95">4.95%</option>
                                                                    <option value="5">5%</option>
                                                                </select>
                                                                {errorsPromptpay.promptpayFees && <p className="error-text">{errorsPromptpay.promptpayFees}</p>}
                                                            </div>

                                                            <div className='col-12 text-center'>
                                                                <button type='submit' className='btn btn1'>Add</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="table-responsive">
                                            <table className='table table1 mb-0'>
                                                <thead>
                                                    <tr>
                                                        <th scope="col" style={{ "width": "50px" }}>#</th>
                                                        <th scope="col">Promptpay Code</th>
                                                        <th scope="col">Fees</th>
                                                        <th scope="col" className='text-center' style={{ "width": "50px" }}>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        filteredPromptpay && filteredPromptpay.length > 0 ? filteredPromptpay.map((items, ind) => {
                                                            return <tr key={ind}>
                                                                <td>{ind + 1}</td>
                                                                <td className='text-wrap'>{items.promptPayCode}</td>
                                                                {
                                                                    items.userAccounts.length > 0 && items.userAccounts.map((itemsFees, ind) => {
                                                                        return <>
                                                                            <td key={`fees${ind}`}>{itemsFees.fees}%</td>
                                                                        </>
                                                                    })
                                                                }
                                                                <td className='text-center'><button onClick={() => deletePromptpay(items.id)} className="btn btn1 py-1 px-2 bg-danger border-danger text-white rounded-2 fs-14" style={{ "minWidth": "auto" }}><FaTrash /></button></td>
                                                            </tr>
                                                        })
                                                        :
                                                        <tr>
                                                            <td colSpan={10}><p className='text-center mt-4'>No Records</p></td>
                                                        </tr>
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-xl-6 col-12 mt-4'>
                                <div className='card card1'>
                                    <div className="card-header">
                                        <h5 className="mb-0 fs-18 fw-500 text-white">Withdraw fees</h5>
                                    </div>
                                    <div className="card-body p-0">
                                        <div className='p-3 p-md-4'>
                                            <div className="row">
                                                <div className='col-12'>
                                                    <form className="form form1" onSubmit={formSubmitFees}>
                                                        <div className='row gy-3 gx-2'>
                                                            <div className='col-xl-6 col-md-6 col-sm-6 col-12'>
                                                                <label className='form-label text-white'>Crypto</label>
                                                                <select className="form-select" aria-label="Default select" value={feesValue.cryptoWithdrawFees} name="cryptoWithdrawFees" onChange={feesHandler}>
                                                                    <option selected disabled value="">Select Fees</option>
                                                                    <option value="0">0%</option>
                                                                    <option value="0.05">0.05%</option>
                                                                    <option value="0.10">0.10%</option>
                                                                    <option value="0.15">0.15%</option>
                                                                    <option value="0.20">0.20%</option>
                                                                    <option value="0.25">0.25%</option>
                                                                    <option value="0.30">0.30%</option>
                                                                    <option value="0.35">0.35%</option>
                                                                    <option value="0.40">0.40%</option>
                                                                    <option value="0.45">0.45%</option>
                                                                    <option value="0.50">0.50%</option>
                                                                    <option value="0.55">0.55%</option>
                                                                    <option value="0.60">0.60%</option>
                                                                    <option value="0.65">0.65%</option>
                                                                    <option value="0.70">0.70%</option>
                                                                    <option value="0.75">0.75%</option>
                                                                    <option value="0.80">0.80%</option>
                                                                    <option value="0.85">0.85%</option>
                                                                    <option value="0.90">0.90%</option>
                                                                    <option value="0.95">0.95%</option>
                                                                    <option value="1">1%</option>
                                                                    <option value="1.05">1.05%</option>
                                                                    <option value="1.10">1.10%</option>
                                                                    <option value="1.15">1.15%</option>
                                                                    <option value="1.20">1.20%</option>
                                                                    <option value="1.25">1.25%</option>
                                                                    <option value="1.30">1.30%</option>
                                                                    <option value="1.35">1.35%</option>
                                                                    <option value="1.40">1.40%</option>
                                                                    <option value="1.45">1.45%</option>
                                                                    <option value="1.50">1.50%</option>
                                                                    <option value="1.55">1.55%</option>
                                                                    <option value="1.60">1.60%</option>
                                                                    <option value="1.65">1.65%</option>
                                                                    <option value="1.70">1.70%</option>
                                                                    <option value="1.75">1.75%</option>
                                                                    <option value="1.80">1.80%</option>
                                                                    <option value="1.85">1.85%</option>
                                                                    <option value="1.90">1.90%</option>
                                                                    <option value="1.95">1.95%</option>
                                                                    <option value="2">2%</option>
                                                                    <option value="2.05">2.05%</option>
                                                                    <option value="2.10">2.10%</option>
                                                                    <option value="2.15">2.15%</option>
                                                                    <option value="2.20">2.20%</option>
                                                                    <option value="2.25">2.25%</option>
                                                                    <option value="2.30">2.30%</option>
                                                                    <option value="2.35">2.35%</option>
                                                                    <option value="2.40">2.40%</option>
                                                                    <option value="2.45">2.45%</option>
                                                                    <option value="2.50">2.50%</option>
                                                                    <option value="2.55">2.55%</option>
                                                                    <option value="2.60">2.60%</option>
                                                                    <option value="2.65">2.65%</option>
                                                                    <option value="2.70">2.70%</option>
                                                                    <option value="2.75">2.75%</option>
                                                                    <option value="2.80">2.80%</option>
                                                                    <option value="2.85">2.85%</option>
                                                                    <option value="2.90">2.90%</option>
                                                                    <option value="2.95">2.95%</option>
                                                                    <option value="3">3%</option>
                                                                    <option value="3.05">3.05%</option>
                                                                    <option value="3.10">3.10%</option>
                                                                    <option value="3.15">3.15%</option>
                                                                    <option value="3.20">3.20%</option>
                                                                    <option value="3.25">3.25%</option>
                                                                    <option value="3.30">3.30%</option>
                                                                    <option value="3.35">3.35%</option>
                                                                    <option value="3.40">3.40%</option>
                                                                    <option value="3.45">3.45%</option>
                                                                    <option value="3.50">3.50%</option>
                                                                    <option value="3.55">3.55%</option>
                                                                    <option value="3.60">3.60%</option>
                                                                    <option value="3.65">3.65%</option>
                                                                    <option value="3.70">3.70%</option>
                                                                    <option value="3.75">3.75%</option>
                                                                    <option value="3.80">3.80%</option>
                                                                    <option value="3.85">3.85%</option>
                                                                    <option value="3.90">3.90%</option>
                                                                    <option value="3.95">3.95%</option>
                                                                    <option value="4">4%</option>
                                                                    <option value="4.05">4.05%</option>
                                                                    <option value="4.10">4.10%</option>
                                                                    <option value="4.15">4.15%</option>
                                                                    <option value="4.20">4.20%</option>
                                                                    <option value="4.25">4.25%</option>
                                                                    <option value="4.30">4.30%</option>
                                                                    <option value="4.35">4.35%</option>
                                                                    <option value="4.40">4.40%</option>
                                                                    <option value="4.45">4.45%</option>
                                                                    <option value="4.50">4.50%</option>
                                                                    <option value="4.55">4.55%</option>
                                                                    <option value="4.60">4.60%</option>
                                                                    <option value="4.65">4.65%</option>
                                                                    <option value="4.70">4.70%</option>
                                                                    <option value="4.75">4.75%</option>
                                                                    <option value="4.80">4.80%</option>
                                                                    <option value="4.85">4.85%</option>
                                                                    <option value="4.90">4.90%</option>
                                                                    <option value="4.95">4.95%</option>
                                                                    <option value="5">5%</option>
                                                                </select>
                                                                {errorsFees.cryptoWithdrawFees && <p className="error-text">{errorsFees.cryptoWithdrawFees}</p>}
                                                            </div>
                                                            
                                                            <div className='col-xl-6 col-md-6 col-sm-6 col-12'>
                                                                <label className='form-label text-white'>Bank transfer</label>
                                                                <select className="form-select" aria-label="Default select" value={feesValue.bankTransferWithdrawFees} name="bankTransferWithdrawFees" onChange={feesHandler}>
                                                                    <option selected disabled value="">Select Fees</option>
                                                                    <option value="0">0%</option>
                                                                    <option value="0.05">0.05%</option>
                                                                    <option value="0.10">0.10%</option>
                                                                    <option value="0.15">0.15%</option>
                                                                    <option value="0.20">0.20%</option>
                                                                    <option value="0.25">0.25%</option>
                                                                    <option value="0.30">0.30%</option>
                                                                    <option value="0.35">0.35%</option>
                                                                    <option value="0.40">0.40%</option>
                                                                    <option value="0.45">0.45%</option>
                                                                    <option value="0.50">0.50%</option>
                                                                    <option value="0.55">0.55%</option>
                                                                    <option value="0.60">0.60%</option>
                                                                    <option value="0.65">0.65%</option>
                                                                    <option value="0.70">0.70%</option>
                                                                    <option value="0.75">0.75%</option>
                                                                    <option value="0.80">0.80%</option>
                                                                    <option value="0.85">0.85%</option>
                                                                    <option value="0.90">0.90%</option>
                                                                    <option value="0.95">0.95%</option>
                                                                    <option value="1">1%</option>
                                                                    <option value="1.05">1.05%</option>
                                                                    <option value="1.10">1.10%</option>
                                                                    <option value="1.15">1.15%</option>
                                                                    <option value="1.20">1.20%</option>
                                                                    <option value="1.25">1.25%</option>
                                                                    <option value="1.30">1.30%</option>
                                                                    <option value="1.35">1.35%</option>
                                                                    <option value="1.40">1.40%</option>
                                                                    <option value="1.45">1.45%</option>
                                                                    <option value="1.50">1.50%</option>
                                                                    <option value="1.55">1.55%</option>
                                                                    <option value="1.60">1.60%</option>
                                                                    <option value="1.65">1.65%</option>
                                                                    <option value="1.70">1.70%</option>
                                                                    <option value="1.75">1.75%</option>
                                                                    <option value="1.80">1.80%</option>
                                                                    <option value="1.85">1.85%</option>
                                                                    <option value="1.90">1.90%</option>
                                                                    <option value="1.95">1.95%</option>
                                                                    <option value="2">2%</option>
                                                                    <option value="2.05">2.05%</option>
                                                                    <option value="2.10">2.10%</option>
                                                                    <option value="2.15">2.15%</option>
                                                                    <option value="2.20">2.20%</option>
                                                                    <option value="2.25">2.25%</option>
                                                                    <option value="2.30">2.30%</option>
                                                                    <option value="2.35">2.35%</option>
                                                                    <option value="2.40">2.40%</option>
                                                                    <option value="2.45">2.45%</option>
                                                                    <option value="2.50">2.50%</option>
                                                                    <option value="2.55">2.55%</option>
                                                                    <option value="2.60">2.60%</option>
                                                                    <option value="2.65">2.65%</option>
                                                                    <option value="2.70">2.70%</option>
                                                                    <option value="2.75">2.75%</option>
                                                                    <option value="2.80">2.80%</option>
                                                                    <option value="2.85">2.85%</option>
                                                                    <option value="2.90">2.90%</option>
                                                                    <option value="2.95">2.95%</option>
                                                                    <option value="3">3%</option>
                                                                    <option value="3.05">3.05%</option>
                                                                    <option value="3.10">3.10%</option>
                                                                    <option value="3.15">3.15%</option>
                                                                    <option value="3.20">3.20%</option>
                                                                    <option value="3.25">3.25%</option>
                                                                    <option value="3.30">3.30%</option>
                                                                    <option value="3.35">3.35%</option>
                                                                    <option value="3.40">3.40%</option>
                                                                    <option value="3.45">3.45%</option>
                                                                    <option value="3.50">3.50%</option>
                                                                    <option value="3.55">3.55%</option>
                                                                    <option value="3.60">3.60%</option>
                                                                    <option value="3.65">3.65%</option>
                                                                    <option value="3.70">3.70%</option>
                                                                    <option value="3.75">3.75%</option>
                                                                    <option value="3.80">3.80%</option>
                                                                    <option value="3.85">3.85%</option>
                                                                    <option value="3.90">3.90%</option>
                                                                    <option value="3.95">3.95%</option>
                                                                    <option value="4">4%</option>
                                                                    <option value="4.05">4.05%</option>
                                                                    <option value="4.10">4.10%</option>
                                                                    <option value="4.15">4.15%</option>
                                                                    <option value="4.20">4.20%</option>
                                                                    <option value="4.25">4.25%</option>
                                                                    <option value="4.30">4.30%</option>
                                                                    <option value="4.35">4.35%</option>
                                                                    <option value="4.40">4.40%</option>
                                                                    <option value="4.45">4.45%</option>
                                                                    <option value="4.50">4.50%</option>
                                                                    <option value="4.55">4.55%</option>
                                                                    <option value="4.60">4.60%</option>
                                                                    <option value="4.65">4.65%</option>
                                                                    <option value="4.70">4.70%</option>
                                                                    <option value="4.75">4.75%</option>
                                                                    <option value="4.80">4.80%</option>
                                                                    <option value="4.85">4.85%</option>
                                                                    <option value="4.90">4.90%</option>
                                                                    <option value="4.95">4.95%</option>
                                                                    <option value="5">5%</option>
                                                                </select>
                                                                {errorsFees.bankTransferWithdrawFees && <p className="error-text">{errorsFees.bankTransferWithdrawFees}</p>}
                                                            </div>

                                                            <div className='col-12 text-center'>
                                                                <button type='submit' className='btn btn1'>Set Fees</button>
                                                            </div>

                                                            {/* {
                                                                adminInfo?.type === "main" && <div className='col-12 text-center'>
                                                                    <button type='submit' className='btn btn1'>Set Fees</button>
                                                                </div>
                                                            }
                                                            {                                   
                                                                adminInfo?.type === "sub" && (!feesValue.cryptoWithdrawFees || !feesValue.bankTransferWithdrawFees) && <div className='col-12 text-center'>
                                                                    <button type='button' className='btn btn1' disabled>Save</button>
                                                                </div>
                                                            }
                                                            {                                   
                                                                adminInfo?.type === "sub" && (feesValue.cryptoWithdrawFees && feesValue.bankTransferWithdrawFees) && <div className='col-12 text-center'>
                                                                    <button type='button' className='btn btn1' data-bs-toggle="modal" data-bs-target="#pinModal">Save</button>
                                                                </div> 
                                                            } */}

                                                            
                                                        </div>
                                                    </form>

                                                    {userFees && <div className='mt-4 text-center'>
                                                        <p className='fs-6 fw-medium mb-2'>Crypto Fees: <span className='text-main'>{userFees.cryptoWithdrawFees}%</span></p>
                                                        <p className='fs-6 fw-medium mb-2'>Bank Transfer Fees: <span className='text-main'>{userFees.bankTransferWithdrawFees}%</span></p>
                                                    </div>}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="modal modal1 fade" id="pinModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div className="modal-dialog modal-dialog-centered" style={{ 'maxWidth': '650px' }}>
                                                <div className="modal-content">
                                                    <div className="modal-header border-0 py-md-5 py-4 px-md-5 px-4">
                                                        {/* <h5 className="modal-title" id="exampleModalLabel">Modal title</h5> */}
                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
                                                    <div className="modal-body px-md-5 px-4 pb-md-5 pb-4">
                                                        <h4 className='text-center mb-4'>Enter all 3 pins</h4>
                                                        <form action="" className='form form1' onSubmit={formSubmitPin}>
                                                            <div className='mb-3'>
                                                                <label className='form-label text-white'>intrabkk@gmail.com</label>
                                                                <input type="text" className='form-control' name="pin1" value={pinValue.pin1} onChange={pinHandler} />
                                                                {pinErrors.pin1 && <p className="error-text">{pinErrors.pin1}</p>}
                                                            </div>

                                                            <div className='mb-3'>
                                                                <label className='form-label text-white'>conti.bangkok@gmail.com</label>
                                                                <input type="text" className='form-control' name="pin2" value={pinValue.pin2} onChange={pinHandler} />
                                                                {pinErrors.pin2 && <p className="error-text">{pinErrors.pin2}</p>}
                                                            </div>

                                                            <div className='mb-3'>
                                                                <label className='form-label text-white'>opgmanager8@gmail.com</label>
                                                                <input type="text" className='form-control' name="pin3" value={pinValue.pin3} onChange={pinHandler} />
                                                                {pinErrors.pin3 && <p className="error-text">{pinErrors.pin3}</p>}
                                                            </div>
                                                            
                                                            <div className='mt-4 text-center'>
                                                                <button type='submit' className='btn btn1 btn-white'>Set Fees</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className='col-xl-6 col-12 mt-4'>
                                <div className='card card1'>
                                    <div className="card-header">
                                        <h5 className="mb-0 fs-18 fw-500 text-white">Add Referral Code</h5>
                                    </div>
                                    <div className="card-body p-0">
                                        <div className='p-3 p-md-4'>
                                            <div className="row">
                                                <div className='col-12'>
                                                    <form className="form form1" onSubmit={formSubmitReferralCode}>
                                                        <div className='row gy-3 gx-2'>
                                                        <div className='col-xl-12 col-md-12 col-sm-12 col-12'>
                                                                <label className='form-label text-white'>Referral Code</label>
                                                                <input type="text" className="form-control" placeholder="" name="referralCode" value={referralCodeValue.referralCode} onChange={referralCodeHandler} />
                                                                {errorsReferralCode.referralCode && <p className="error-text">{errorsReferralCode.referralCode}</p>}
                                                            </div>

                                                            <div className='col-12 text-center'>
                                                                {
                                                                    userDetails && userDetails.referralCode ? <button type='submit' className='btn btn1' disabled>Set Code</button>
                                                                    : <button type='submit' className='btn btn1'>Set Code</button>
                                                                }
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            {/* <div className='col-lg-12 col-12 mt-4'>
                                <div className='card card1'>
                                    <div className="card-header">
                                        <h5 className="mb-0 fs-18 fw-500 text-white">User Transaction</h5>
                                    </div>
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className='table table1 mb-0' style={{ "minWidth": "650px" }}>
                                                <thead>
                                                    <tr>
                                                        <th scope="col" style={{ "width": "50px" }}>#</th>
                                                        <th scope="col">Transaction Id</th>
                                                        <th scope="col">Address</th>
                                                        <th scope="col">Amount</th>
                                                        <th scope="col">Date Time</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        userTransaction && userTransaction.length > 0 ? userTransaction.map((items, ind) => {
                                                            const date = new Date(items.createdAt).toLocaleDateString()
                                                            const time = new Date(items.createdAt).toLocaleTimeString()
                                                            return <tr key={ind}>
                                                                <td>{ind + 1}</td>
                                                                <td>{items.transactionId}</td>
                                                                <td>{items.address}</td>
                                                                <td>${items.amount}</td>
                                                                <td>{time} <br /> {date}</td>
                                                            </tr>
                                                        })
                                                        :
                                                        <tr>
                                                            <td colSpan={5}><p className='text-center mt-4'>No Records</p></td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        </>
    )
}

export default UserTransaction
