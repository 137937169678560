import React, { useEffect, useRef, useState } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { useAllContext } from '../context/AllContext'
import ReactPaginate from 'react-paginate'
import axios from 'axios'
import { toast } from 'react-toastify'
import { FaRegTrashAlt } from 'react-icons/fa'
import { Fancybox } from '@fancyapps/ui'
import { Link } from 'react-router-dom'

const CreateCryptoAccount = () => {

    const adminInfo = JSON.parse(localStorage.getItem('adminInfo'))

    const { getCryptoAccounts, cryptoAccounts } = useAllContext()

    const [inputValue, setInputValue] = useState({
        walletAddress: "",
        currency: "",
        network: ""
    });

    const [qrImage, setQrImage] = useState("")
    const imageInputRef = useRef();

    const [errors, setErrors] = useState({});

    const inputHandler = (e) => {
        const {name, value} = e.target;
        setInputValue({
            ...inputValue,
            [name]: value
        })
    }

    const fileHandler = (event) => {
        const file = event.target.files[0];
        setQrImage(file)
    };

    
    const validate = () => {
        let errors = {};
    
        if (!inputValue.walletAddress) {
          errors.walletAddress = 'Wallet Address is required';
        }
    
        if (!inputValue.currency) {
            errors.currency = 'Currency is required';
        }

        if (qrImage === "" || qrImage == undefined || qrImage == null) {
            errors.qrImage = "QR Image is required"
        } 
        else if (!qrImage.name.match(/\.(jpg|jpeg|png|svg|webp)$/)) {
            errors.qrImage = "select valid image."
        }
    
        return errors;
    };
    
    const formSubmit = async (e) => {
        e.preventDefault()
        
        const formErrors = validate();
        setErrors(formErrors);
        
        const accountData = new FormData()

        accountData.append('image', qrImage)
        accountData.append('walletAddress', inputValue.walletAddress)
        accountData.append('currency', inputValue.currency)
        accountData.append('network', inputValue.network)
        
        if (Object.keys(formErrors).length === 0) {
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/add-crypto-account`, accountData, {
                    headers: {
                        Authorization: `${adminInfo.token}`
                    }
                })
                const data = await response.data

                if(data.status === 0){
                    toast.error(data.message)
                }

                if(data.status === 1){
                    toast.success(data.message)
                    getCryptoAccounts(adminInfo.token)
                    imageInputRef.current.value = "";
                    setQrImage("");
                    setInputValue({
                        walletAddress: "",
                        currency: "",
                        network: ""
                    })
                }

            } catch (error) {
                console.log('Error:', error.response.data.message);
                toast.error(error.response.data.message)
            }
        }
    }

    // pagination
    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 8
    useEffect(() => {
        if (cryptoAccounts) {
            // Calculate the total number of pages
            const endOffset = itemOffset + itemsPerPage;
            setCurrentItems(cryptoAccounts.slice(itemOffset, endOffset));
            setPageCount(Math.ceil(cryptoAccounts.length / itemsPerPage));
        }
        
    }, [itemOffset, itemsPerPage, cryptoAccounts]);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % cryptoAccounts.length;
        setItemOffset(newOffset);
        setCurrentPage(event.selected)
    };
    // end pagination

    useEffect(() => {   
        if (inputValue.currency !== "USDT") {
            setInputValue({
                ...inputValue,
                network: ""
            })
        }
    }, [inputValue.currency])

    const deleteAccount = async (id) => {
        const bankData = {
            id: `${id}`
        }
        try {
            const resp = await axios.post(`${process.env.REACT_APP_API_URL}/admin/delete-crypto-account`, bankData, {
                headers: {
                    Authorization: `${adminInfo.token}`
                }
            })
            const data = await resp.data

            if (data.status == 0) {
                toast.error(data.message)
            }

            if (data.status == 1) {
                toast.success(data.message)
                getCryptoAccounts(adminInfo.token)
            }


        } catch (error) {
            console.log('Error:', error);
        }
    }

    useEffect(() => {
        getCryptoAccounts(adminInfo.token)
    }, [])

    Fancybox.bind("[data-fancybox='']", {
        Thumbs: {
            autoStart: false,
        },
    });

    const openImage = (url) => {
        Fancybox.show([{ src: url, type: 'image' }]);
    }
    

    return (
        <>
            <div className='main-content'>
                <Header />

                <div className='padding'>
                    <div className='container-fluid'>
                        <div className='row gx-3'>
                            <div className='col-lg-12 col-12'>
                                <div className='card card1'>
                                    <div className="card-header">
                                        <h5 className="mb-0 fs-18 fw-500 text-white">Create Crypto Account</h5>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className='col-xl-7 col-lg-8 col-md-10 col-12'>
                                                <form className="form form1" onSubmit={formSubmit}>
                                                    <div className='mb-3'>
                                                        <label className='form-label text-white'>QR Image</label>
                                                        <div className="icon-box">
                                                            <input type="file" className="form-control ps-2" accept='image/*' ref={imageInputRef} name="qrImage" onChange={fileHandler} />
                                                            {errors.qrImage && <p className="error-text">{errors.qrImage}</p>}
                                                        </div>
                                                    </div>

                                                    <div className='mb-3'>
                                                        <label className='form-label text-white'>Wallet Address</label>
                                                        <div className="icon-box">
                                                            <input type="text" className="form-control" placeholder="" value={inputValue.walletAddress} name="walletAddress" onChange={inputHandler} />
                                                            {errors.walletAddress && <p className="error-text">{errors.walletAddress}</p> }
                                                        </div>
                                                    </div>

                                                    <div className='mb-3'>
                                                        <label className='form-label text-white'>Currency</label>
                                                        <div className="icon-box">
                                                            <select className="form-select ms-auto" aria-label="Default select" value={inputValue.currency} name="currency" onChange={inputHandler}>
                                                                <option selected disabled value="">Select Currency</option>
                                                                <option value="BTC">BTC</option>
                                                                <option value="USDT">USDT</option>
                                                            </select>
                                                            {errors.currency && <p className="error-text">{errors.currency}</p>}
                                                        </div>
                                                    </div>
                                                    
                                                    {
                                                        inputValue.currency == "USDT" && <div className='mb-3'>
                                                            <label className='form-label text-white'>Network</label>
                                                            <div className="icon-box">
                                                            <select className="form-select ms-auto" aria-label="Default select" value={inputValue.network} name="network" onChange={inputHandler}>
                                                                    <option selected disabled value="">Select Network</option>
                                                                    <option value="TRC-20">TRC-20</option>
                                                                    <option value="ERC-20">ERC-20</option>
                                                                </select>
                                                                {errors.network && <p className="error-text">{errors.network}</p>}
                                                            </div>
                                                        </div>
                                                    
                                                    }
                                                    
                                                    <div className='mt-4'>
                                                        <button type='submit' className='btn btn1'>Save</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-lg-12 col-12 mt-4'>
                                <div className='card card1'>
                                    <div className="card-header">
                                        <h5 className="mb-0 fs-18 fw-500 text-white">Crypto Accounts</h5>
                                    </div>
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className='table table1 mb-0' style={{ "minWidth": "650px" }}>
                                                <thead>
                                                    <tr>
                                                        <th scope="col" style={{ "width": "50px" }}>#</th>
                                                        <th scope="col" style={{"width": "50px"}}>QR Image</th>
                                                        <th scope="col">Wallet Address</th>
                                                        <th scope="col">Currency</th>
                                                        <th scope="col">Network</th>
                                                        <th scope="col" className='text-center'>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        currentItems && currentItems.length > 0 ? currentItems.map((items, ind) => {
                                                            const date = new Date(items.createdAt).toLocaleDateString()
                                                            return <tr key={ind}>
                                                                <td>{currentPage * itemsPerPage + ind + 1 }</td>
                                                                <td><Link onClick={ () => openImage(`${process.env.REACT_APP_IMAGES_URL}/${items.QRImage}`) } to={"#"} data-fancybox=""><img src={`${process.env.REACT_APP_IMAGES_URL}/${items.QRImage}`} className="img-fluid rounded" alt="" style={{ "width": "80px", "minWidth": "80px" }} /></Link></td>
                                                                <td>{items.walletAddress}</td>
                                                                <td>{items.currency}</td>
                                                                <td>{items.network}</td>
                                                                <td className="text-center"><button type="submit" onClick={() => {deleteAccount(items.id)}} className="btn btn1 py-1 px-2 bg-danger border-danger text-white" style={{"minWidth": "auto"}}><FaRegTrashAlt /></button></td>
                                                            </tr>
                                                        })
                                                        : 
                                                        <tr>
                                                            <td colSpan={10}><p className='text-center mt-4'>No Records</p></td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className='col-12'>
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel=">"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={1}
                                    pageCount={pageCount}
                                    previousLabel="<"
                                    renderOnZeroPageCount={null}
                                    containerClassName={'pagination'}
                                    pageClassName={'page-item'}
                                    pageLinkClassName={'page-link'}
                                    previousClassName={'page-item'}
                                    previousLinkClassName={'page-link'}
                                    nextClassName={'page-item'}
                                    nextLinkClassName={'page-link'}
                                    breakClassName={'page-item'}
                                    breakLinkClassName={'page-link'}
                                    activeClassName={'active'}
                                    className={'pagination pagination1 mx-auto justify-content-center mt-4 mb-0'}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        </>
    )
}

export default CreateCryptoAccount
