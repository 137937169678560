import React, { useEffect, useRef, useState } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { useAllContext } from '../context/AllContext'
import ReactPaginate from 'react-paginate'
import axios from 'axios'
import { toast } from 'react-toastify'
import { FaRegTrashAlt } from 'react-icons/fa'
import { MdEdit } from 'react-icons/md'
import { Fancybox } from '@fancyapps/ui'
import { Link } from 'react-router-dom'

const CreateBankAccount = () => {

    const adminInfo = JSON.parse(localStorage.getItem('adminInfo'))

    const { getBankAccounts, bankAccounts } = useAllContext()

    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);

    const [inputValue, setInputValue] = useState({
        bankName: "",
        bankAccountNumber: "",
        bankAccountName: ""
    });

    const [errors, setErrors] = useState({});

    const inputHandler = (e) => {
        const {name, value} = e.target;
        setInputValue({
            ...inputValue,
            [name]: value
        })
    }

    const [qrImage, setQrImage] = useState("")
    const imageInputRef = useRef();

    const fileHandler = (event) => {
        const file = event.target.files[0];
        setQrImage(file)
    };


    const itemsPerPage = 8
    useEffect(() => {
        if (bankAccounts) {
            // Calculate the total number of pages
            const endOffset = itemOffset + itemsPerPage;
            setCurrentItems(bankAccounts.slice(itemOffset, endOffset));
            setPageCount(Math.ceil(bankAccounts.length / itemsPerPage));
        }
        
    }, [itemOffset, itemsPerPage, bankAccounts]);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % bankAccounts.length;
        setItemOffset(newOffset);
        setCurrentPage(event.selected)
    };
    

    const validate = () => {
        let errors = {};
    
        if (!inputValue.bankName) {
            errors.bankName = "Bank Name is required"
        }
    
        if (!inputValue.bankAccountNumber) {
            errors.bankAccountNumber = "Bank Account Number is required"
        }
    
        if (!inputValue.bankAccountName) {
            errors.bankAccountName = "Bank Account Name is required"
        }

        if (qrImage === "" || qrImage == undefined || qrImage == null) {
            // errors.qrImage = "QR Image is required"
        } 
        else if (!qrImage.name.match(/\.(jpg|jpeg|png|svg|webp)$/)) {
            errors.qrImage = "select valid image."
        }
    
        return errors;
    };

    const formSubmit = async (e) => {
        e.preventDefault()
        
        const formErrors = validate();
        setErrors(formErrors);

        const accountData = new FormData()

        accountData.append('image', qrImage)
        accountData.append('bankName', inputValue.bankName)
        accountData.append('bankAccountNumber', inputValue.bankAccountNumber)
        accountData.append('bankAccountName', inputValue.bankAccountName)
        
        
        if (Object.keys(formErrors).length === 0) {
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/add-bank-account`, accountData, {
                    headers: {
                        Authorization: `${adminInfo.token}`
                    }
                })
                const data = await response.data

                if(data.status === 0){
                    toast.error(data.message)
                }

                if(data.status === 1){
                    toast.success(data.message)
                    getBankAccounts(adminInfo.token)
                    imageInputRef.current.value = ""
                    setQrImage("")
                    setInputValue({
                        bankName: "",
                        bankAccountNumber: "",
                        bankAccountName: ""
                    })
                }

            } catch (error) {
                console.log('Error:', error.response.data.message);
                toast.error(error.response.data.message)
            }
        }
    }

    const deleteUserAccount = async (id) => {
        const bankData = {
            id: `${id}`
        }
        try {
            const resp = await axios.post(`${process.env.REACT_APP_API_URL}/admin/delete-bank-account`, bankData, {
                headers: {
                    Authorization: `${adminInfo.token}`
                }
            })
            const data = await resp.data

            if (data.status == 0) {
                toast.error(data.message)
            }

            if (data.status == 1) {
                toast.success(data.message)
                getBankAccounts(adminInfo.token)
            }


        } catch (error) {
            console.log('Error:', error);
        }
        
    }


    useEffect(() => {
        getBankAccounts(adminInfo.token)
    }, [])

    Fancybox.bind("[data-fancybox='']", {
        Thumbs: {
            autoStart: false,
        },
    });

    const openImage = (url) => {
        Fancybox.show([{ src: url, type: 'image' }]);
    }

    return (
        <>
            <div className='main-content'>
                <Header />

                <div className='padding'>
                    <div className='container-fluid'>
                        <div className='row gx-3'>
                            <div className='col-lg-12 col-12'>
                                <div className='card card1'>
                                    <div className="card-header">
                                        <h5 className="mb-0 fs-18 fw-500 text-white">Create Bank Account</h5>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className='col-xl-7 col-lg-8 col-md-10 col-12'>
                                                <form className="form form1" onSubmit={formSubmit}>
                                                    <div className='mb-3'>
                                                        <label className='form-label text-white'>Bank Name</label>
                                                        <div className="icon-box">
                                                            <input type="text" className="form-control" placeholder="" value={inputValue.bankName} name="bankName" onChange={inputHandler} />
                                                            {errors.bankName && <p className="error-text">{errors.bankName}</p> }
                                                        </div>
                                                    </div>

                                                    <div className='mb-3'>
                                                        <label className='form-label text-white'>Bank Account Number</label>
                                                        <div className="icon-box">
                                                            <input type="text" className="form-control" placeholder="" value={inputValue.bankAccountNumber} name="bankAccountNumber" onChange={inputHandler} />
                                                            {errors.bankAccountNumber && <p className="error-text">{errors.bankAccountNumber}</p> }
                                                        </div>
                                                    </div>

                                                    <div className='mb-3'>
                                                        <label className='form-label text-white'>Bank Account Name</label>
                                                        <div className="icon-box">
                                                            <input type="text" className="form-control" placeholder="" value={inputValue.bankAccountName} name="bankAccountName" onChange={inputHandler} />
                                                            {errors.bankAccountName && <p className="error-text">{errors.bankAccountName}</p> }
                                                        </div>
                                                    </div>

                                                    <div className='mb-3'>
                                                        <label className='form-label text-white'>QR Image <span class="text-white-50">(Optional)</span></label>
                                                        <div className="icon-box">
                                                            <input type="file" className="form-control ps-2" accept='image/*' ref={imageInputRef} name="qrImage" onChange={fileHandler} />
                                                            {errors.qrImage && <p className="error-text">{errors.qrImage}</p>}
                                                        </div>
                                                    </div>
                                                    
                                                    <div className='mt-4'>
                                                        <button type='submit' className='btn btn1'>Create</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-lg-12 col-12 mt-4'>
                                <div className='card card1'>
                                    <div className="card-header">
                                        <h5 className="mb-0 fs-18 fw-500 text-white">Bank Accounts</h5>
                                    </div>
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className='table table1 mb-0' style={{ "minWidth": "650px" }}>
                                                <thead>
                                                    <tr>
                                                        <th scope="col" style={{ "width": "50px" }}>#</th>
                                                        <th scope="col" style={{"width": "50px"}}>QR Image</th>
                                                        <th scope="col">Bank Name</th>
                                                        <th scope="col">Bank Account Number</th>
                                                        <th scope="col">Bank Account Name</th>
                                                        <th scope="col">Date Time</th>
                                                        <th scope="col" className='text-center'>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        currentItems && currentItems.length > 0 ? currentItems.map((items, ind) => {
                                                            const date = new Date(items.createdAt).toLocaleDateString()
                                                            return <tr key={ind}>
                                                                <td>{currentPage * itemsPerPage + ind + 1 }</td>
                                                                <td><Link onClick={ () => openImage(`${process.env.REACT_APP_IMAGES_URL}/${items.imageName}`) } to={"#"} data-fancybox=""><img src={`${process.env.REACT_APP_IMAGES_URL}/${items.imageName}`} className="img-fluid rounded" alt="" style={{ "width": "80px", "minWidth": "80px" }} /></Link></td>
                                                                <td>{items.bankName}</td>
                                                                <td>{items.bankAccountNumber}</td>
                                                                <td>{items.bankAccountName}</td>
                                                                <td>{date}</td>
                                                                <td className="text-center">
                                                                    <button type="submit" className="btn btn1 py-1 px-2 bg-danger border-danger text-white" onClick={() => deleteUserAccount(items.id)} style={{"minWidth": "auto"}}><FaRegTrashAlt /></button>
                                                                    <Link to={`/update-bank-account/${items.id}`} className="btn btn1 py-1 px-2 text-white ms-2" style={{"minWidth": "auto"}}><MdEdit /></Link>
                                                                </td>
                                                            </tr>
                                                        })
                                                        : 
                                                        <tr>
                                                            <td colSpan={5}><p className='text-center mt-4'>No Records</p></td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className='col-12'>
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel=">"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={1}
                                    pageCount={pageCount}
                                    previousLabel="<"
                                    renderOnZeroPageCount={null}
                                    containerClassName={'pagination'}
                                    pageClassName={'page-item'}
                                    pageLinkClassName={'page-link'}
                                    previousClassName={'page-item'}
                                    previousLinkClassName={'page-link'}
                                    nextClassName={'page-item'}
                                    nextLinkClassName={'page-link'}
                                    breakClassName={'page-item'}
                                    breakLinkClassName={'page-link'}
                                    activeClassName={'active'}
                                    className={'pagination pagination1 mx-auto justify-content-center mt-4 mb-0'}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        </>
    )
}

export default CreateBankAccount
