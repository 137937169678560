import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { useAllContext } from '../context/AllContext'
import { useParams } from 'react-router-dom'
import ReactPaginate from 'react-paginate'

const UserWithdraw = () => {

    const adminInfo = JSON.parse(localStorage.getItem('adminInfo'))

    const { getUserWithdraw, userWithdraw } = useAllContext()

    const params = useParams()
    
    
    // pagination
    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 8
    useEffect(() => {
        // Calculate the total number of pages
        if (userWithdraw) {
            const endOffset = itemOffset + itemsPerPage;
            setCurrentItems(userWithdraw.slice(itemOffset, endOffset));
            setPageCount(Math.ceil(userWithdraw.length / itemsPerPage));
        }
        
    }, [itemOffset, itemsPerPage, userWithdraw]);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % userWithdraw.length;
        setItemOffset(newOffset);
        setCurrentPage(event.selected)
    };
    // end pagination

    useEffect(() => {
        getUserWithdraw(adminInfo.token, params.userId)
    }, [])

    return (
        <>
            <div className='main-content'>
                <Header />

                <div className='padding'>
                    <div className='container-fluid'>
                        <div className='row gx-3'>
                            <div className='col-lg-12 col-12'>
                                <div className='card card1'>
                                    <div className="card-header">
                                        <h5 className="mb-0 fs-18 fw-500 text-white">User Withdraw</h5>
                                    </div>
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className='table table1 mb-0' style={{ "minWidth": "650px" }}>
                                                <thead>
                                                    <tr>
                                                        <th scope="col" style={{"width": "50px"}}>#</th>
                                                        <th scope="col">Withdraw Type</th>
                                                        <th scope="col">Withdraw Method</th>
                                                        <th scope="col">Bank Name</th>
                                                        <th scope="col">Bank Account Number</th>
                                                        <th scope="col">Bank Account Name</th>
                                                        <th scope="col">Currency</th>
                                                        <th scope="col">Network</th>
                                                        <th scope="col">Wallet Address</th>
                                                        <th scope="col">Amount</th>
                                                        <th scope="col">Time Period</th>
                                                        <th scope="col">Date Time</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        currentItems && currentItems.length > 0 ? currentItems.map((items, ind) => {
                                                            const date = new Date(items.createdAt).toLocaleDateString()
                                                            const time = new Date(items.createdAt).toLocaleTimeString()
                                                            return <tr key={ind}>
                                                                <td>{currentPage * itemsPerPage + ind + 1 }</td>
                                                                <td>{items.withdrawType}</td>
                                                                <td>{items.withdrawMethod}</td>
                                                                <td>{items.bankName}</td>
                                                                <td>{items.bankAccountNumber}</td>
                                                                <td>{items.bankAccountName}</td>
                                                                <td>{items.currency}</td>
                                                                <td>{items.network}</td>
                                                                <td>{items.walletAddress}</td>
                                                                <td>${items.amount}</td>
                                                                <td>{items.timePeriod}</td>
                                                                <td>{time} <br /> {date}</td>
                                                            </tr>
                                                        })
                                                        : 
                                                        <tr>
                                                            <td colSpan={10}><p className='text-center mt-4'>No Records</p></td>
                                                        </tr>
                                                    }
                                                    
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-12'>
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel=">"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={1}
                                    pageCount={pageCount}
                                    previousLabel="<"
                                    renderOnZeroPageCount={null}
                                    containerClassName={'pagination'}
                                    pageClassName={'page-item'}
                                    pageLinkClassName={'page-link'}
                                    previousClassName={'page-item'}
                                    previousLinkClassName={'page-link'}
                                    nextClassName={'page-item'}
                                    nextLinkClassName={'page-link'}
                                    breakClassName={'page-item'}
                                    breakLinkClassName={'page-link'}
                                    activeClassName={'active'}
                                    className={'pagination pagination1 mx-auto justify-content-center mt-4 mb-0'}
                                />
                            </div>

                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        </>
    )
}

export default UserWithdraw
